import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../CSS/Sidebar.css';
import AuthUser from '../components/AuthUser';
import { useEffect } from 'react';
import http from '../http';
import { FiAnchor, FiChevronDown, FiUnlock } from 'react-icons/fi';

export default function SideBar() {
  const location = useLocation();
  const { getToken } = AuthUser();
  const user = getToken();
  const [userRole, setUserRole] = useState({
    home_page: [],
    administration: [],
    service: [],
    user: [],
  });
  const [data, setData] = useState({
    image: '',
  });
  return (
    <div className='sidebar-style'>
      <nav className='sidebar'>
        <div className='sidebar-header'>
          <a style={{ width: '50%' }} href='/' className='sidebar-brand'>
            <img
              style={{ width: '50%' }}
              src={`${global.img_Url}/${data.image}`}
              alt=''
              srcset=''
            />
          </a>
          {/* <p>Salse</p> */}
          <div className='sidebar-toggler not-active'>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className='sidebar-body'>
          <ul className='nav'>
            <li
              className={`nav-item ${
                location.pathname === '/group' ||
                location.pathname === '/category' ||
                location.pathname === '/sub-category' ||
                location.pathname === '/resource'
                  ? 'active'
                  : ''
              }`}
            >
              <a
                className='nav-link'
                data-bs-toggle='collapse'
                href='#emails'
                role='button'
                aria-expanded={`${
                  location.pathname === '/group' ||
                  location.pathname === '/category' ||
                  location.pathname === '/sub-category' ||
                  location.pathname === '/resource'
                    ? 'true'
                    : ''
                }`}
                aria-controls='emails'
              >
                <FiAnchor className='link-icon' />
                <span className='link-title'>Patient Education</span>
                {/* <i className="link-arrow" data-feather="chevron-down" strokeWidth="2" fill="#6E6" /> */}
                <FiChevronDown className='link-arrow' />
              </a>
              <div
                className={`collapse ${
                  location.pathname === '/group' ||
                  location.pathname === '/category' ||
                  location.pathname === '/sub-category' ||
                  location.pathname === '/resource'
                    ? 'show'
                    : ''
                }`}
                id='emails'
              >
                <ul className='nav sub-menu'>
                  <li className='nav-item'>
                    <Link
                      to='/group'
                      className={`nav-link ${
                        location.pathname === '/group' ? 'active' : ''
                      }`}
                    >
                      Group
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/category'
                      className={`nav-link ${
                        location.pathname === '/category' ? 'active' : ''
                      }`}
                    >
                      Category
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/sub-category'
                      className={`nav-link ${
                        location.pathname === '/sub-category' ? 'active' : ''
                      }`}
                    >
                      Sub Category
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/resource'
                      className={`nav-link ${
                        location.pathname === '/resource' ? 'active' : ''
                      }`}
                    >
                      Resource
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`nav-item ${
                location.pathname === '/medicaleducation' ||
                location.pathname === '/newsandviews' ||
                location.pathname === '/webinars'
                  ? 'active'
                  : ''
              }`}
            >
              <a
                className='nav-link'
                data-bs-toggle='collapse'
                href='#others'
                role='button'
                aria-expanded={`${
                  location.pathname === '/medicaleducation' ||
                  location.pathname === '/newsandviews' ||
                  location.pathname === '/webinars'
                    ? 'true'
                    : ''
                }`}
                aria-controls='others'
              >
                <FiAnchor className='link-icon' />
                <span className='link-title'>Others Setup</span>
                {/* <i className="link-arrow" data-feather="chevron-down" strokeWidth="2" fill="#6E6" /> */}
                <FiChevronDown className='link-arrow' />
              </a>
              <div
                className={`collapse ${
                  location.pathname === '/medicaleducation' ||
                  location.pathname === '/newsandviews' ||
                  location.pathname === '/webinars'
                    ? 'show'
                    : ''
                }`}
                id='others'
              >
                <ul className='nav sub-menu'>
                  <li className='nav-item'>
                    <Link
                      to='/medicaleducation'
                      className={`nav-link ${
                        location.pathname === '/medicaleducation'
                          ? 'active'
                          : ''
                      }`}
                    >
                      Medical Education
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/newsandviews'
                      className={`nav-link ${
                        location.pathname === '/newsandviews' ? 'active' : ''
                      }`}
                    >
                      News and Views
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/webinars'
                      className={`nav-link ${
                        location.pathname === '/webinars' ? 'active' : ''
                      }`}
                    >
                      Webinars
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`nav-item ${
                location.pathname === '/users' ? 'active' : ''
              }`}
            >
              <a
                className='nav-link'
                data-bs-toggle='collapse'
                href='#users'
                role='button'
                aria-expanded={`${
                  location.pathname === '/users' ? 'true' : ''
                }`}
                aria-controls='users'
              >
                <FiAnchor className='link-icon' />
                <span className='link-title'>Manage User</span>
                <FiChevronDown className='link-arrow' />
              </a>
              <div
                className={`collapse ${
                  location.pathname === '/users' ? 'show' : ''
                }`}
                id='users'
              >
                <ul className='nav sub-menu'>
                  <li className='nav-item'>
                    <Link
                      to='/users'
                      className={`nav-link ${
                        location.pathname === '/users' ? 'active' : ''
                      }`}
                    >
                      Users
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
