import JoditEditor from 'jodit-react';
import MaterialTable from 'material-table';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import http from '../http';
import Swal from 'sweetalert2';
import { toast } from 'react-hot-toast';

Modal.setAppElement('#root');
const SubCategory = () => {
  const [isOpenForSave, setIsOpenForSave] = React.useState(false);
  const [isOpenForEdit, setIsOpenForEdit] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const [subCategoryUpdate, setSubCategoryUpdate] = React.useState();
  const [data, setData] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [group, setGroup] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [groupId, setGroupId] = React.useState();

  useEffect(() => {
    // get for Group
    http
      .get('/group/get')
      .then((res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // get For Category
    http
      .get('/category/get')
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    //
    http
      .get('/subCategory/get')
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refetch]);
  const closeModalForSave = () => {
    setIsOpenForSave(false);
  };
  const closeModalForEdit = () => {
    setIsOpenForEdit(false);
    setGroupId('');
  };

  const deleteSubcategory = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .delete(`subCategory/delete/${id}`)
          .then((res) => {
            setRefetch(!refetch);
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Success!',
              text: 'Data Deleted Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const editSubCategory = (row) => {
    setSubCategoryUpdate(row);
    const findCategory = category.find((c) => {
      return Number(c.id) === Number(row?.categoryId);
    });
    const findGroup = group.find((g) => {
      return Number(g.id) === Number(findCategory?.groupId);
    });
    setGroupId(findGroup?.id);
    setIsOpenForEdit(true);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append('subCategoryName', event.target.subCategoryName.value);
    // formData.append('file', event.target.file.files[0]);
    formData.append('categoryId', subCategoryUpdate?.categoryId);
    // formData.append('subCategoryContent', subCategoryUpdate.subCategoryContent);
    if (file) {
      formData.append('file', file);
    }
    http
      .put(`subCategory/edit/${subCategoryUpdate.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          setRefetch(!refetch);
          toast.success('Data Updated Successfully');
          setIsOpenForEdit(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const fromData = new FormData();
    const subCategoryName = event.target.subCategoryName.value;
    // const file = event.target.file.files[0];
    // const subCategoryContent = event.target.subCategoryContent.value;
    const categoryId = event.target.categoryId.value;

    // fromData.append('file', file);
    fromData.append('subCategoryName', subCategoryName);
    // fromData.append('subCategoryContent', subCategoryContent);
    fromData.append('categoryId', categoryId);
    http
      .post('/subCategory/create', fromData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setRefetch(!refetch);
          setIsOpenForSave(false);
          toast.success('Data Added Successfully');
        }
      })
      .catch((err) => {
        console.log(err);
        setIsOpenForSave(true);
        toast.error('Something went wrong');
      });
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '58%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '90%',
      width: '76%',
      height: '450px',
      padding: '10px',
    },
  };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      width: '20 !important',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Group Name',
      render: (row) => {
        const findCategory = category.find((c) => {
          return c.id === row.categoryId;
        });
        const findGroup = group.find((g) => {
          return g.id === findCategory?.groupId;
        });

        return <p>{findGroup?.groupName}</p>;
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Category Name',
      render: (row) => {
        const findCategory = category.find((c) => {
          return c.id === row.categoryId;
        });
        return <p>{findCategory?.categoryName}</p>;
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Sub Category Name',
      field: `subCategoryName`,
      cellStyle: {
        textAlign: 'center',
      },
    },

    {
      title: 'Action',
      render: (row) => (
        <div>
          <button
            onClick={() => editSubCategory(row)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-edit'></i>
          </button>
          <button
            onClick={() => deleteSubcategory(row.id)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-trash'></i>
          </button>
        </div>
      ),
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return (
    <div className='page-content adjustment-type-table'>
      <div className='custom-card p-2 d-flex justify-content-between mb-2 align-items-center'>
        <h6>Sub Category List</h6>
        <div>
          <button
            style={{ marginTop: '1px' }}
            onClick={() => setIsOpenForSave(true)}
            className='btn btn-sm btn-primary float-end'
          >
            Add Sub Category
          </button>
        </div>
      </div>
      <MaterialTable
        columns={columns}
        data={data || []}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: 'left',
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      {/* Save Modal  */}
      <Modal
        isOpen={isOpenForSave}
        onRequestClose={closeModalForSave}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='product_modal'>
          <div className='page-content'>
            <div className=' patients-head '>
              <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-name'>
                Add Sub Category
                <span
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={closeModalForSave}
                  className='float-end'
                >
                  <i className='fal fa-times'></i>
                </span>
              </h5>
            </div>

            <div className=' p-3'>
              <form onSubmit={handleSubmit}>
                <select
                  name='groupId'
                  required
                  onChange={(e) => {
                    setGroupId(e.target.value);
                  }}
                  className='form-control form-control-sm my-2'
                >
                  <option value={''}>Select Group</option>
                  {group &&
                    group.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.groupName}
                        </option>
                      );
                    })}
                </select>
                <select
                  name='categoryId'
                  required
                  className='form-control form-control-sm my-2'
                >
                  <option value={''}>Select Category</option>
                  {group &&
                    category &&
                    category
                      .filter((c) => Number(c.groupId) === Number(groupId))
                      .map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.categoryName}
                          </option>
                        );
                      })}
                </select>
                <input
                  name='subCategoryName'
                  type='text'
                  className='form-control form-control-sm my-2'
                  required
                  placeholder='Sub Category Name'
                />
                <button
                  className='btn mt-2 btn-sm btn-success float-end text-uppercase'
                  type='submit'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    class='feather feather-save mb-1'
                  >
                    <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                    <polyline points='17 21 17 13 7 13 7 21'></polyline>
                    <polyline points='7 3 7 8 15 8'></polyline>
                  </svg>{' '}
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      {/* edit Modal  */}
      <Modal
        isOpen={isOpenForEdit}
        onRequestClose={closeModalForEdit}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='product_modal'>
          <div className='page-content'>
            <div className=' patients-head '>
              <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-name'>
                Edit Sub Category
                <span
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={closeModalForEdit}
                  className='float-end'
                >
                  <i className='fal fa-times'></i>
                </span>
              </h5>
            </div>

            <div className=' p-3'>
              <form onSubmit={handleEditSubmit}>
                <select
                  name='groupId'
                  required
                  onChange={(e) => {
                    setSubCategoryUpdate({
                      ...subCategoryUpdate,
                      groupId: e.target.value,
                    });
                    setGroupId(e.target.value);
                  }}
                  className='form-control form-control-sm my-2'
                >
                  <option value={''}>Select Group</option>
                  {group &&
                    group.map((item, index) => {
                      return (
                        <option
                          selected={+item.id === +groupId}
                          key={index}
                          value={item.id}
                        >
                          {item.groupName}
                        </option>
                      );
                    })}
                </select>
                <select
                  name='categoryId'
                  required
                  onChange={(e) => {
                    setSubCategoryUpdate({
                      ...subCategoryUpdate,
                      categoryId: e.target.value,
                    });
                  }}
                  className='form-control form-control-sm my-2'
                >
                  <option value={''}>Select Category</option>
                  {group &&
                    category &&
                    category
                      ?.filter((c) => +c.groupId === +groupId)
                      ?.map((item, index) => {
                        return (
                          <option
                            style={{
                              color: 'black',
                            }}
                            selected={
                              +item.id === +subCategoryUpdate?.categoryId
                            }
                            key={index}
                            value={item.id}
                          >
                            {item.categoryName}
                          </option>
                        );
                      })}
                </select>
                <input
                  name='subCategoryName'
                  type='text'
                  value={subCategoryUpdate?.subCategoryName || null}
                  onChange={(e) =>
                    setSubCategoryUpdate((prev) => ({
                      ...prev,
                      subCategoryName: e.target.value,
                    }))
                  }
                  className='form-control form-control-sm my-2'
                  required
                  placeholder='Sub Category Name'
                />
                <button
                  className='btn mt-2 btn-sm btn-success float-end text-uppercase'
                  type='submit'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    class='feather feather-save mb-1'
                  >
                    <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                    <polyline points='17 21 17 13 7 13 7 21'></polyline>
                    <polyline points='7 3 7 8 15 8'></polyline>
                  </svg>{' '}
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SubCategory;
