import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import http from '../../http';

export default function Dashboard() {
  const [patientEducations, setPatientEducations] = useState(0);
  const [totalNews, setTotalNews] = useState(0);
  const [totalWebinars, setWebinars] = useState(0);
  const [totalMedicalEducations, setMedicalEducations] = useState(0);
  useEffect(() => {
    const apiRequests = [
      http.get('resource/get'),
      http.get('newsAndViews/get'),
      http.get('webinars/get'),
      http.get('medicalEducation/get'),
    ];

    Promise.all(apiRequests)
      .then((responses) => {
        setPatientEducations(responses[0].data.data.length);
        setTotalNews(responses[1].data.data.length);
        setWebinars(responses[2].data.data.length);
        setMedicalEducations(responses[3].data.data.length);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <div className='page-content'>
      <div className='row'>
        <div className='col-md-3'>
          <div className='custom-card p-3'>
            <div className='d-flex justify-content-between align-items-center'>
              <span
                style={{ fontSize: '20px', fontWeight: 500, color: '#8A96A5' }}
              >
                Patient Educations
              </span>
            </div>
            <div className='d-flex mt-2'>
              <div className='dashboard-icon d-flex justify-content-center align-items-center'>
                <i className='fa-solid fa-user-doctor'></i>
              </div>
              <div>
                <span
                  className='ms-3'
                  style={{ fontSize: '25px', fontWeight: '700' }}
                >
                  {patientEducations}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='custom-card p-3'>
            <div className='d-flex justify-content-between align-items-center'>
              <span
                style={{ fontSize: '20px', fontWeight: 500, color: '#8A96A5' }}
              >
                News And Views
              </span>
            </div>
            <div className='d-flex mt-2'>
              <div className='dashboard-icon d-flex justify-content-center align-items-center'>
                <i className='fa-solid fa-user-doctor'></i>
              </div>
              <div>
                <span
                  className='ms-3'
                  style={{ fontSize: '25px', fontWeight: '700' }}
                >
                  {totalNews}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='custom-card p-3'>
            <div className='d-flex justify-content-between align-items-center'>
              <span
                style={{ fontSize: '20px', fontWeight: 500, color: '#8A96A5' }}
              >
                Medical Education
              </span>
            </div>
            <div className='d-flex mt-2'>
              <div className='dashboard-icon d-flex justify-content-center align-items-center'>
                <i className='fa-solid fa-user-doctor'></i>
              </div>
              <div>
                <span
                  className='ms-3'
                  style={{ fontSize: '25px', fontWeight: '700' }}
                >
                  {totalMedicalEducations}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-3'>
          <div className='custom-card p-3'>
            <div className='d-flex justify-content-between align-items-center'>
              <span
                style={{ fontSize: '20px', fontWeight: 500, color: '#8A96A5' }}
              >
                Webinars
              </span>
            </div>
            <div className='d-flex mt-2'>
              <div className='dashboard-icon d-flex justify-content-center align-items-center'>
                <i className='fa-solid fa-user-doctor'></i>
              </div>
              <div>
                <span
                  className='ms-3'
                  style={{ fontSize: '25px', fontWeight: '700' }}
                >
                  {totalWebinars}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
