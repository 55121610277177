import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import http from '../http';
import Swal from 'sweetalert2';
import { toast } from 'react-hot-toast';
Modal.setAppElement('#root');
const Users = () => {
  const [isOpenForSave, setIsOpenForSave] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [isShowPassword, setIsShowPassword] = useState(false);
  useEffect(() => {
    http
      .get('/auth/get')
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refetch]);
  const closeModalForSave = () => {
    setIsOpenForSave(false);
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .delete(`auth/delete/${id}`)
          .then((res) => {
            setRefetch(!refetch);
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Success!',
              text: 'Data Deleted Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error('Something went wrong');
          });
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const password = event.target.password.value;

    const data = {
      name,
      email,
      password,
    };
    // return;
    http
      .post('/auth/register', data)
      .then((res) => {
        if (res.status === 201) {
          console.log('fromData', res.data);
          setRefetch(!refetch);
          setIsOpenForSave(false);
          toast.success('Successfully created');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
        setIsOpenForSave(true);
      });
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '58%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '90%',
      width: '76%',
      height: '450px',
      padding: '10px',
    },
  };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      width: '20 !important',
    },
    {
      title: 'Name',
      field: `name`,
    },
    {
      title: 'Email',
      field: `email`,
    },

    {
      title: 'Action',
      field: 'patient',
      render: (row) => (
        <div>
          {/* <button
            onClick={() => editMedicalEducation(row)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-edit'></i>
          </button> */}
          <button
            onClick={() => deleteUser(row?.id)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-trash'></i>
          </button>
        </div>
      ),
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return (
    <div className='page-content adjustment-type-table'>
      <div className='custom-card p-2 d-flex justify-content-between mb-2 align-items-center'>
        <h6>User List</h6>
        <div>
          <button
            style={{ marginTop: '1px' }}
            onClick={() => setIsOpenForSave(true)}
            className='btn btn-sm btn-primary float-end'
          >
            Add User
          </button>
        </div>
      </div>
      <MaterialTable
        columns={columns}
        data={data || []}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: 'left',
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      {/* Save Modal  */}
      <Modal
        isOpen={isOpenForSave}
        onRequestClose={closeModalForSave}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='product_modal'>
          <div className='page-content'>
            <div className=' patients-head '>
              <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-name'>
                Add User
                <span
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={closeModalForSave}
                  className='float-end'
                >
                  <i className='fal fa-times'></i>
                </span>
              </h5>
            </div>

            <div className=' p-3'>
              <form onSubmit={handleSubmit}>
                <input
                  name='name'
                  type='text'
                  className='form-control form-control-sm my-2'
                  required
                  autoComplete='off'
                  placeholder='Enter User Name'
                />
                <input
                  name='email'
                  type='email'
                  className='form-control form-control-sm my-2'
                  required
                  autoComplete='off'
                  placeholder='User Email Address'
                />
                <input
                  name='password'
                  type={isShowPassword ? 'text' : 'password'}
                  onFocus={() => setIsShowPassword(true)}
                  onBlur={() => setIsShowPassword(false)}
                  className='form-control form-control-sm my-2'
                  required
                  autoComplete='off'
                  placeholder='Enter Password'
                />
                <button
                  className='btn mt-2 btn-sm btn-success float-end text-uppercase'
                  type='submit'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    class='feather feather-save mb-1'
                  >
                    <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                    <polyline points='17 21 17 13 7 13 7 21'></polyline>
                    <polyline points='7 3 7 8 15 8'></polyline>
                  </svg>{' '}
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Users;
