import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import http from '../http';
import Swal from 'sweetalert2';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
Modal.setAppElement('#root');
const MedicalEducation = () => {
  const [isOpenForSave, setIsOpenForSave] = React.useState(false);
  const [isOpenForEdit, setIsOpenForEdit] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const [updatedMedicalEducation, setUpdatedMedicalEducation] = React.useState(
    {},
  );
  const [data, setData] = React.useState([]);
  const [file, setFile] = React.useState(null);
  useEffect(() => {
    http
      .get('/medicalEducation/get')
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refetch]);
  const closeModalForSave = () => {
    setIsOpenForSave(false);
  };
  const closeModalForEdit = () => {
    setIsOpenForEdit(false);
    setUpdatedMedicalEducation({});
  };

  const deleteMedicalEducation = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .delete(`medicalEducation/delete/${id}`)
          .then((res) => {
            setRefetch(!refetch);
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Success!',
              text: 'Data Deleted Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((err) => {
            toast.error('Something went wrong');
          });
      }
    }); // <-- Missing parenthesis here
  };

  const editMedicalEducation = (row) => {
    setUpdatedMedicalEducation(row);
    setIsOpenForEdit(true);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append('title', updatedMedicalEducation.title);
    formData.append('videoUrl', updatedMedicalEducation.videoUrl);
    formData.append('description', updatedMedicalEducation.description);
    if (file) {
      formData.append('file', file);
    }
    http
      .put(`medicalEducation/edit/${updatedMedicalEducation.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          setRefetch(!refetch);
          toast.success('Data Updated Successfully');
          setIsOpenForEdit(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const fromData = new FormData();
    const title = event.target.title.value;
    const videoUrl = event.target.videoUrl.value;
    const description = event.target.description.value;
    const file = event.target.thumbnail.files[0];
    fromData.append('file', file); // thumbnail
    fromData.append('title', title);
    fromData.append('videoUrl', videoUrl);
    fromData.append('description', description);
    // return;
    http
      .post('/medicalEducation/create', fromData)
      .then((res) => {
        if (res.status === 200) {
          console.log('fromData', res.data);
          setRefetch(!refetch);
          setIsOpenForSave(false);
          toast.success('Successfully Added');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
        setIsOpenForSave(true);
      });
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '58%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '90%',
      width: '76%',
      height: '450px',
      padding: '10px',
    },
  };

  const columns = [
    {
      title: 'ID',
      field: 'id',
      width: '20 !important',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Title',
      field: `title`,
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Video Url',
      render: (row) => {
        return (
          <a
            href={row?.videoUrl}
            className='btn btn-sm btn-primary'
            target='_blank'
          >
            View
          </a>
        );
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Thumbnail',
      field: `thumbnail`,
      render: (row) => {
        if (row?.thumbnail) {
          return (
            <img
              src={`${global.img_Url}/${row.thumbnail}`}
              alt='thumbnail'
              height={50}
            />
          );
        } else {
          return <p>No Thumbnail Found</p>;
        }
      },
      cellStyle: {
        textAlign: 'center',
      },
    },

    {
      title: 'Action',
      field: 'patient',
      render: (row) => (
        <div>
          <button
            onClick={() => editMedicalEducation(row)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-edit'></i>
          </button>
          <button
            onClick={() => deleteMedicalEducation(row.id)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-trash'></i>
          </button>
        </div>
      ),
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];
  return (
    <div className='page-content adjustment-type-table'>
      <div className='custom-card p-2 d-flex justify-content-between mb-2 align-items-center'>
        <h6>Medical Education List</h6>
        <div>
          <button
            style={{ marginTop: '1px' }}
            onClick={() => setIsOpenForSave(true)}
            className='btn btn-sm btn-primary float-end'
          >
            Add Medical Education
          </button>
        </div>
      </div>
      <MaterialTable
        columns={columns}
        data={data || []}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: 'left',
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      {/* Save Modal  */}
      <Modal
        isOpen={isOpenForSave}
        onRequestClose={closeModalForSave}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='product_modal'>
          <div className='page-content'>
            <div className=' patients-head '>
              <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-name'>
                Add Medical Education
                <span
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={closeModalForSave}
                  className='float-end'
                >
                  <i className='fal fa-times'></i>
                </span>
              </h5>
            </div>

            <div className=' p-3'>
              <form onSubmit={handleSubmit}>
                <input
                  name='title'
                  type='text'
                  className='form-control form-control-sm my-2'
                  required
                  placeholder='Title'
                />
                <input
                  name='videoUrl'
                  type='url'
                  className='form-control form-control-sm my-2'
                  required
                  placeholder='Video Url'
                />
                <input
                  name='thumbnail'
                  type='file'
                  required
                  accept='image/*'
                  className='form-control form-control-sm my-2'
                />

                <textarea
                  name='description'
                  className='form-control form-control-sm my-2'
                ></textarea>
                <button
                  className='btn mt-2 btn-sm btn-success float-end text-uppercase'
                  type='submit'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    class='feather feather-save mb-1'
                  >
                    <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                    <polyline points='17 21 17 13 7 13 7 21'></polyline>
                    <polyline points='7 3 7 8 15 8'></polyline>
                  </svg>{' '}
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      {/* edit Modal  */}
      <Modal
        isOpen={isOpenForEdit}
        onRequestClose={closeModalForEdit}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='product_modal'>
          <div className='page-content'>
            <div className=' patients-head '>
              <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-name'>
                Edit Group
                <span
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={closeModalForEdit}
                  className='float-end'
                >
                  <i className='fal fa-times'></i>
                </span>
              </h5>
            </div>

            <div className=' p-3'>
              <form onSubmit={handleEditSubmit}>
                <input
                  name='title'
                  type='text'
                  value={updatedMedicalEducation?.title || null}
                  onChange={(e) =>
                    setUpdatedMedicalEducation((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  className='form-control form-control-sm my-2'
                  required
                  placeholder='Title'
                />
                <input
                  name='videoUrl'
                  type='url'
                  required
                  value={updatedMedicalEducation?.videoUrl || null}
                  onChange={(e) =>
                    setUpdatedMedicalEducation((prev) => ({
                      ...prev,
                      videoUrl: e.target.value,
                    }))
                  }
                  className='form-control form-control-sm my-2'
                  placeholder='Video Url'
                />
                <input
                  name='file'
                  placeholder='Thumbnail'
                  type='file'
                  accept='image/*'
                  onChange={(e) => setFile(e.target.files[0])}
                  className='form-control form-control-sm my-2'
                />
                <textarea
                  name='description'
                  value={updatedMedicalEducation?.description || null}
                  onChange={(e) =>
                    setUpdatedMedicalEducation((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  className='form-control form-control-sm my-2'
                ></textarea>
                <button
                  className='btn mt-2 btn-sm btn-success float-end text-uppercase'
                  type='submit'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    class='feather feather-save mb-1'
                  >
                    <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                    <polyline points='17 21 17 13 7 13 7 21'></polyline>
                    <polyline points='7 3 7 8 15 8'></polyline>
                  </svg>
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MedicalEducation;
