import JoditEditor from "jodit-react";
import MaterialTable from "material-table";
import React, { useEffect } from "react";
import Modal from "react-modal";
import http from "../http";
import Swal from "sweetalert2";
import { toast } from "react-hot-toast";
Modal.setAppElement("#root");
const Category = () => {
  const [isOpenForSave, setIsOpenForSave] = React.useState(false);
  const [isOpenForEdit, setIsOpenForEdit] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const [categoryUpdate, setCategoryUpdate] = React.useState();
  const [data, setData] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [group, setGroup] = React.useState([]);
  const [preview, setPreview] = React.useState(null);

  useEffect(() => {
    // get for Group
    http
      .get("/group/get")
      .then((res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // get For Category
    http
      .get("/category/get")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refetch]);

  const closeModalForSave = () => {
    setIsOpenForSave(false);
    setCategoryUpdate({});
    setPreview(null);
  };
  const closeModalForEdit = () => {
    setIsOpenForEdit(false);
    setCategoryUpdate({});
    setPreview(null);
  };

  const deleteCategory = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .delete(`category/delete/${id}`)
          .then((res) => {
            setRefetch(!refetch);
            Swal.fire({
              position: "top-center",
              icon: "success",
              title: "Success!",
              text: "Data Deleted Successfully",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong");
          });
      }
    });
  };

  const editCategory = (row) => {
    setCategoryUpdate(row);
    if (row.categoryImage) {
      setPreview(`${global.img_Url}/${row.categoryImage}`);
    } else {
      setPreview(null);
    }
    setIsOpenForEdit(true);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("categoryName", categoryUpdate.categoryName);
    formData.append("groupId", categoryUpdate?.groupId);
    const fileCategory = event.target.file.files[0];

    if (fileCategory) {
      formData.append("file", fileCategory);
    }
    http
      .put(`category/edit/${categoryUpdate.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          setRefetch(!refetch);
          toast.success("Data Updated Successfully");
          setIsOpenForEdit(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const fromData = new FormData();
    const categoryName = event.target.categoryName.value;
    const file = event.target.file.files[0];
    // const categoryContent = event.target.categoryContent.value;
    const groupId = event.target.groupId.value;

    fromData.append("file", file);
    fromData.append("categoryName", categoryName);
    // fromData.append('categoryContent', categoryContent);
    fromData.append("groupId", groupId);
    // return;
    http
      .post("/category/create", fromData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setRefetch(!refetch);
          setIsOpenForSave(false);
          setPreview(null);
          toast.success("Data Added Successfully");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setIsOpenForSave(true);
      });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "58%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "90%",
      width: "76%",
      height: "450px",
      padding: "10px",
    },
  };

  const columns = [
    {
      title: "ID",
      field: "id",
      width: "20 !important",
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Group Name",
      render: (row) => {
        const findGroup = group.find((g) => {
          return g.id === row.groupId;
        });
        return <p>{findGroup?.groupName}</p>;
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Category Name",
      field: `categoryName`,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Category Image",
      render: (row) => {
        return (
          <>
            {row.categoryImage ? (
              <img
                src={`${global.img_Url}/${row.categoryImage}`}
                alt="category_image"
                height={50}
              />
            ) : (
              <p>No Image Found</p>
            )}
          </>
        );
      },
    },

    {
      title: "Action",
      render: (row) => (
        <div>
          <button
            onClick={() => editCategory(row)}
            className="btn btn-sm action-btn"
          >
            <i className="far fa-edit"></i>
          </button>
          <button
            onClick={() => deleteCategory(row.id)}
            className="btn btn-sm action-btn"
          >
            <i className="far fa-trash"></i>
          </button>
        </div>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
  return (
    <div className="page-content adjustment-type-table">
      <div className="custom-card p-2 d-flex justify-content-between mb-2 align-items-center">
        <h6>Category List</h6>
        <div>
          <button
            style={{ marginTop: "1px" }}
            onClick={() => setIsOpenForSave(true)}
            className="btn btn-sm btn-primary float-end"
          >
            Add Category
          </button>
        </div>
      </div>
      <MaterialTable
        columns={columns}
        data={data || []}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: "left",
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
      {/* Save Modal  */}
      <Modal
        isOpen={isOpenForSave}
        onRequestClose={closeModalForSave}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="product_modal">
          <div className="page-content">
            <div className=" patients-head ">
              <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">
                Add Category
                <span
                  style={{ cursor: "pointer", fontSize: "16px" }}
                  onClick={closeModalForSave}
                  className="float-end"
                >
                  <i className="fal fa-times"></i>
                </span>
              </h5>
            </div>

            <div className=" p-3">
              <form onSubmit={handleSubmit}>
                <select
                  required
                  name="groupId"
                  className="form-control form-control-sm my-2"
                >
                  <option value="">Select Group</option>
                  {group &&
                    group.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.groupName}
                        </option>
                      );
                    })}
                </select>
                <div>
                  <input
                    name="categoryName"
                    type="text"
                    className="form-control form-control-sm my-2"
                    required
                    placeholder="Category Name"
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <input
                      name="file"
                      type="file"
                      accept="image/*"
                      height={"100px"}
                      onChange={(e) => {
                        setPreview(URL.createObjectURL(e.target.files[0]));
                      }}
                      required
                    />
                    {/* preview Image  */}
                    <div
                      style={{
                        height: "100px",
                        width: "100px",
                        border: "1px solid black",
                        borderRadius: "5px",
                        display: "inline-block",
                      }}
                    >
                      {preview && (
                        <img
                          src={preview}
                          alt="preview"
                          style={{ height: "100%", width: "100%" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="btn mt-2 btn-sm btn-success float-end text-uppercase"
                  type="submit"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-save mb-1"
                  >
                    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                    <polyline points="17 21 17 13 7 13 7 21"></polyline>
                    <polyline points="7 3 7 8 15 8"></polyline>
                  </svg>{" "}
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      {/* edit Modal  */}
      <Modal
        isOpen={isOpenForEdit}
        onRequestClose={closeModalForEdit}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="product_modal">
          <div className="page-content">
            <div className=" patients-head ">
              <h5 className="fw-normal custom_py-3 px-2  text-start mb-2 card-name">
                Edit Category
                <span
                  style={{ cursor: "pointer", fontSize: "16px" }}
                  onClick={closeModalForEdit}
                  className="float-end"
                >
                  <i className="fal fa-times"></i>
                </span>
              </h5>
            </div>

            <div className=" p-3">
              <form onSubmit={handleEditSubmit}>
                <select
                  name="groupId"
                  required
                  onChange={(e) => {
                    setCategoryUpdate((prev) => ({
                      ...prev,
                      groupId: e.target.value,
                    }));
                  }}
                  className="form-control form-control-sm my-2"
                >
                  <option value={""}>Select Group</option>
                  {group &&
                    group.map((item, index) => {
                      return (
                        <option
                          selected={+categoryUpdate?.groupId === +item.id}
                          key={index}
                          value={item.id}
                        >
                          {item.groupName}
                        </option>
                      );
                    })}
                </select>
                <input
                  name="categoryName"
                  type="text"
                  value={categoryUpdate?.categoryName || null}
                  onChange={(e) =>
                    setCategoryUpdate((prev) => ({
                      ...prev,
                      categoryName: e.target.value,
                    }))
                  }
                  className="form-control form-control-sm my-2"
                  required
                  placeholder="Category Name"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "10px",
                  }}
                >
                  <input
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={(e) =>
                      setPreview(URL.createObjectURL(e.target.files[0]))
                    }
                    // required
                  />
                  <div
                    style={{
                      display: "inline-block",
                      height: "100px",
                      width: "100px",
                      border: "1px solid black",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    {preview && (
                      <img
                        src={preview}
                        alt="preview"
                        width={"100px"}
                        height={"100px"}
                      />
                    )}
                  </div>
                </div>
                <button
                  className="btn mt-2 btn-sm btn-success float-end text-uppercase"
                  type="submit"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-save mb-1"
                  >
                    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                    <polyline points="17 21 17 13 7 13 7 21"></polyline>
                    <polyline points="7 3 7 8 15 8"></polyline>
                  </svg>{" "}
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Category;
