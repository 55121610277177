import React, { useEffect } from 'react';
import http from '../http';
import MaterialTable from 'material-table';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';

function Resource() {
  const [group, setGroup] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState([]);
  const [refetch, setRefetch] = React.useState(false);
  const [isOpenForSave, setIsOpenForSave] = React.useState(false);
  const [isOpenForEdit, setIsOpenForEdit] = React.useState(false);
  const [resourceUpdate, setResourceUpdate] = React.useState();
  const [data, setData] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [groupId, setGroupId] = React.useState();
  const [categoryId, setCategoryId] = React.useState();
  // const [fileType, setFileType] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [subCategoryId, setSubCategoryId] = React.useState('');

  useEffect(() => {
    // get for Group
    http
      .get('/group/get')
      .then((res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // get For Category
    http
      .get('/category/get')
      .then((res) => {
        setCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // sub Category
    http
      .get('/subCategory/get')
      .then((res) => {
        setSubCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get('/resource/get')
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refetch]);

  const closeModalForSave = () => {
    setIsOpenForSave(false);
    setResourceUpdate({});
  };
  const closeModalForEdit = () => {
    setIsOpenForEdit(false);
    setResourceUpdate({});
  };

  const deleteResource = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .delete(`resource/delete/${id}`)
          .then((res) => {
            setRefetch(!refetch);
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'Success!',
              text: 'Data Deleted Successfully',
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error('Something went wrong!');
          });
      }
    });
  };
  const handleEditSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    let formData = new FormData();
    formData.append('subCategoryId', resourceUpdate?.subCategoryId);
    formData.append('title', resourceUpdate?.title);
    formData.append('fileContent', resourceUpdate?.fileContent);
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.slice(
        ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
      );
      const allowedExtensions = [
        'jpg',
        'jpeg',
        'gif',
        'webp',
        'png',
        'pdf',
        'doc',
        'docx',
      ];
      const maxFileSizeInBytes = 20971520; // Set to 20MB

      if (
        allowedExtensions.includes(fileExtension) &&
        file.size <= maxFileSizeInBytes
      ) {
        formData.append('file', file);
      } else {
        setLoading(false);
        if (!allowedExtensions.includes(fileExtension)) {
          toast.error('File type not supported');
        } else {
          toast.error('File size exceeds the allowed limit');
        }
        return;
      }
    }
    http
      .put(`resource/edit/${resourceUpdate.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          setRefetch(!refetch);
          toast.success('Data Updated Successfully');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
        setIsOpenForEdit(false);
      });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();

    const subCategoryId = event.target.subCategoryId.value;
    const title = event.target.title.value;
    const file = event.target?.file?.files[0];
    const embedUrl = event.target?.embedUrl?.value || '';
    const fileContent = event.target.fileContent.value;

    formData.append('subCategoryId', subCategoryId);
    formData.append('title', title);
    formData.append('embedUrl', embedUrl);
    formData.append('fileContent', fileContent);
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.slice(
        ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
      );
      const allowedExtensions = [
        'jpg',
        'jpeg',
        'gif',
        'webp',
        'png',
        'pdf',
        'doc',
        'docx',
      ];
      const maxFileSizeInBytes = 20971520; // Set to 20MB

      if (
        allowedExtensions.includes(fileExtension) &&
        file.size <= maxFileSizeInBytes
      ) {
        formData.append('file', file);
      } else {
        setLoading(false);
        if (!allowedExtensions.includes(fileExtension)) {
          toast.error('File type not supported');
        } else {
          toast.error('File size exceeds the allowed limit');
        }
        return;
      }
    }

    http
      .post('/resource/create', formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setRefetch(!refetch);
          setIsOpenForSave(false);
          toast.success('Data Added Successfully');
        }
      })
      .catch((err) => {
        console.log(err);
        setIsOpenForSave(true);
        toast.error('Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editResource = (row) => {
    setResourceUpdate(row);
    setIsOpenForEdit(true);
    const findSubCategory = subCategory.find((c) => {
      return +c.id === +row.subCategoryId;
    });
    const findCategory = category.find((c) => {
      return +c.id === +findSubCategory.categoryId;
    });
    const findGroup = group.find((g) => {
      return +g.id === +findCategory.groupId;
    });
    setGroupId(findGroup?.id);
    setCategoryId(findCategory?.id);
    setSubCategoryId(findSubCategory?.id);
  };
  const columns = [
    {
      title: 'ID',
      field: 'id',
      width: '20 !important',
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Group Name',
      render: (row) => {
        const findSubCategory = subCategory.find((c) => {
          return +c.id === +row?.subCategoryId;
        });
        const findCategory = category.find((c) => {
          return +c.id === +findSubCategory?.categoryId;
        });
        const findGroup = group.find((g) => {
          return +g.id === +findCategory?.groupId;
        });

        return <p>{findGroup?.groupName}</p>;
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Category Name',
      render: (row) => {
        const findSubCategory = subCategory.find((c) => {
          return c.id === row?.subCategoryId;
        });
        const findCategory = category.find((c) => {
          return c.id === findSubCategory?.categoryId;
        });
        return <p>{findCategory?.categoryName}</p>;
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Sub Category Name',
      render: (row) => {
        const findSubCategory = subCategory.find((c) => {
          return c.id === row.subCategoryId;
        });
        return <p>{findSubCategory?.subCategoryName}</p>;
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: ' Resource Title',
      field: 'title',
      cellStyle: {
        textAlign: 'center',
      },
    },
    // {
    //   title: 'Embed Url',
    //   field: 'embedUrl',
    //   cellStyle: {
    //     textAlign: 'center',
    //   },
    // },
    {
      title: 'file',
      render: (row) => {
        const fileExtension = row?.file?.split('.').pop();
        if (fileExtension === 'pdf') {
          return (
            <a target='_blank' href={`${global.img_Url}/${row.file}`}>
              <i className='fas fa-file-pdf'></i>
            </a>
          );
        }
        if (fileExtension === 'doc' || fileExtension === 'docx') {
          return (
            <a href={`${global.img_Url}/${row.file}`} target='_blank'>
              <i className='fas fa-file-word'></i>
            </a>
          );
        }
        if (row.file) {
          return (
            <img
              src={`${global.img_Url}/${row.file}`}
              width='50'
              alt='file-img'
            />
          );
        }
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Action',
      render: (row) => (
        <div>
          <button
            onClick={() => editResource(row)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-edit'></i>
          </button>
          <button
            onClick={() => deleteResource(row.id)}
            className='btn btn-sm action-btn'
          >
            <i className='far fa-trash'></i>
          </button>
        </div>
      ),
      cellStyle: {
        textAlign: 'center',
      },
    },
  ];

  const customStyles = {
    content: {
      top: '50%',
      left: '58%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxHeight: '90%',
      width: '76%',
      height: '450px',
      padding: '10px',
    },
  };
  return (
    <div className='page-content adjustment-type-table'>
      <div className='custom-card p-2 d-flex justify-content-between mb-2 align-items-center'>
        <h6>Resource List</h6>
        <div>
          <button
            style={{ marginTop: '1px' }}
            onClick={() => setIsOpenForSave(true)}
            className='btn btn-sm btn-primary float-end'
          >
            Add Resource
          </button>
        </div>
      </div>
      <MaterialTable
        columns={columns}
        data={data || []}
        options={{
          search: true,
          showTitle: false,
          searchFieldAlignment: 'left',
          pageSize: 10,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />

      {/* Save Modal Start */}
      <Modal
        isOpen={isOpenForSave}
        onRequestClose={closeModalForSave}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='product_modal'>
          <div className='page-content'>
            <div className=' patients-head '>
              <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-name'>
                Add Resource
                <span
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={closeModalForSave}
                  className='float-end'
                >
                  <i className='fal fa-times'></i>
                </span>
              </h5>
            </div>

            <div className=' p-3'>
              <form
                onSubmit={handleSubmit}
                className='d-flex flex-column gap-2'
              >
                <div className='form-group form-group-sm'>
                  <label htmlFor='group'>Select Group</label>
                  <select
                    name='groupId'
                    id='group'
                    required
                    onChange={(e) => {
                      setGroupId(e.target.value);
                    }}
                    className='form-control form-control-sm my-2'
                  >
                    <option value={''}>Select Group</option>
                    {group &&
                      group.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.groupName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className='form-group form-group-sm'>
                  <label htmlFor='category'> Select Category</label>
                  <select
                    name='categoryId'
                    required
                    id='category'
                    onChange={(e) => {
                      setCategoryId(e.target.value);
                    }}
                    className='form-control form-control-sm my-2'
                  >
                    <option value={''}>Select Category</option>
                    {group &&
                      category &&
                      category
                        .filter((c) => Number(c.groupId) === Number(groupId))
                        .map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.categoryName}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <div className='form-group form-group-sm'>
                  <label htmlFor='sub-category'> Select Sub Category</label>
                  <select
                    name='subCategoryId'
                    id='sub-category'
                    required
                    className='form-control form-control-sm my-2'
                  >
                    <option value={''}>Select Sub Category</option>
                    {subCategory &&
                      subCategory
                        ?.filter(
                          (c) => Number(c.categoryId) === Number(categoryId),
                        )
                        ?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.subCategoryName}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <div className='form-group form-group-sm'>
                  <label htmlFor='title'>Title</label>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    required
                    className='form-control'
                  />
                </div>
                {/* <div className='form-group form-group-sm'>
                  <label htmlFor='file-type'>Select File Type</label>
                  <select
                    className='form-control'
                    onChange={(e) => setFileType(e.target.value)}
                    id='file-type'
                  >
                    <option value={''}>Select File Type</option>
                    <option value='file'>Upload File</option>
                    <option value='url'>Upload Video URL</option>
                  </select>
                </div> */}

                <div className='form-group form-group-sm'>
                  {/* {fileType === 'file' && (
                    <> */}
                  <label htmlFor='file'>Upload File</label>
                  <input
                    type='file'
                    id='file'
                    name='file'
                    required
                    className='form-control'
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  {/* </>
                  )}
                  {fileType === 'url' && (
                    <>
                      <div className='form-group form-group-sm'>
                        <label htmlFor='thumb'>Upload Video Thumbnail</label>
                        <input
                          type='file'
                          id='thumb'
                          name='thumb'
                          required
                          className='form-control'
                        />
                      </div>
                      <div className='form-group form-group-sm'>
                        <label htmlFor='embedUrl'>Write Video URL</label>
                        <input
                          type='url'
                          id='embedUrl'
                          name='embedUrl'
                          required
                          className='form-control'
                        />
                      </div>
                    </>
                  )} */}
                </div>

                <div className='form-group'>
                  <label htmlFor='resource-content'>Write Content</label>
                  <textarea
                    id='resource-content'
                    name='fileContent'
                    placeholder='write here'
                    className='form-control form-control-sm my-2'
                  ></textarea>
                </div>
                <div>
                  <button
                    className='btn mt-2 btn-sm btn-success float-end text-uppercase'
                    type='submit'
                    disabled={loading}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='feather feather-save mb-1'
                    >
                      <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                      <polyline points='17 21 17 13 7 13 7 21'></polyline>
                      <polyline points='7 3 7 8 15 8'></polyline>
                    </svg>{' '}
                    {loading ? 'loading...' : 'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={isOpenForEdit}
        onRequestClose={closeModalForEdit}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <div className='product_modal'>
          <div className='page-content'>
            <div className=' patients-head '>
              <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-name'>
                Edit Resource
                <span
                  style={{ cursor: 'pointer', fontSize: '16px' }}
                  onClick={closeModalForEdit}
                  className='float-end'
                >
                  <i className='fal fa-times'></i>
                </span>
              </h5>
            </div>

            <div className=' p-3'>
              <form
                onSubmit={handleEditSubmit}
                className='d-flex flex-column gap-2'
              >
                <div className='form-group form-group-sm'>
                  <label htmlFor='group'>Select Group</label>
                  <select
                    name='groupId'
                    id='group'
                    required
                    onChange={(e) => {
                      setResourceUpdate({
                        ...resourceUpdate,
                        groupId: e.target.value,
                      });
                      setGroupId(e.target.value);
                    }}
                    className='form-control form-control-sm my-2'
                  >
                    <option value={''}>Select Group</option>
                    {group &&
                      group.map((item, index) => {
                        return (
                          <option
                            selected={+item.id === +groupId}
                            key={index}
                            value={item.id}
                          >
                            {item.groupName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className='form-group form-group-sm'>
                  <label htmlFor='category'> Select Category</label>
                  <select
                    name='categoryId'
                    required
                    id='category'
                    onChange={(e) => {
                      setResourceUpdate({
                        ...resourceUpdate,
                        categoryId: e.target.value,
                      });
                      setCategoryId(e.target.value);
                    }}
                    className='form-control form-control-sm my-2'
                  >
                    <option value={''}>Select Category</option>
                    {group &&
                      category &&
                      category
                        .filter((c) => Number(c.groupId) === Number(groupId))
                        .map((item, index) => {
                          return (
                            <option
                              selected={+item.id === +categoryId}
                              key={index}
                              value={item.id}
                            >
                              {item.categoryName}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <div className='form-group form-group-sm'>
                  <label htmlFor='sub-category'> Select Sub Category</label>
                  <select
                    name='subCategoryId'
                    id='sub-category'
                    required
                    onChange={(e) => {
                      setResourceUpdate({
                        ...resourceUpdate,
                        subCategoryId: e.target.value,
                      });
                    }}
                    className='form-control form-control-sm my-2'
                  >
                    <option value={''}>Select Sub Category</option>
                    {subCategory &&
                      subCategory
                        ?.filter(
                          (c) => Number(c.categoryId) === Number(categoryId),
                        )
                        ?.map((item, index) => {
                          return (
                            <option
                              selected={+item.id === +subCategoryId}
                              key={index}
                              value={item.id}
                            >
                              {item.subCategoryName}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <div className='form-group form-group-sm'>
                  <label htmlFor='title'>Title</label>
                  <input
                    type='text'
                    id='title'
                    name='title'
                    value={resourceUpdate?.title}
                    onChange={(e) =>
                      setResourceUpdate((prev) => {
                        return { ...prev, title: e.target.value };
                      })
                    }
                    required
                    className='form-control'
                  />
                </div>
                {/* <div className='form-group form-group-sm'>
                  <label htmlFor='file-type'>Select File Type</label>
                  <select
                    className='form-control'
                    onChange={(e) => setFileType(e.target.value)}
                    id='file-type'
                  >
                    <option value={''}>Select File Type</option>
                    <option value='file'>Upload File</option>
                    <option value='url'>Upload Video URL</option>
                  </select>
                </div> */}

                <div className='form-group form-group-sm'>
                  {/* {fileType === 'file' && (
                    <> */}
                  <label htmlFor='file'>Upload File</label>
                  <input
                    type='file'
                    id='file'
                    name='file'
                    className='form-control'
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  {/* </>
                  )}
                  {fileType === 'url' && (
                    <>
                      <div className='form-group form-group-sm'>
                        <label htmlFor='thumb'>Upload Video Thumbnail</label>
                        <input
                          type='file'
                          id='thumb'
                          name='thumb'
                          required
                          onChange={(e) => setFile(e.target.files[0])}
                          className='form-control'
                        />
                      </div>
                      <div className='form-group form-group-sm'>
                        <label htmlFor='embedUrl'>Write Video URL</label>
                        <input
                          type='url'
                          id='embedUrl'
                          value={resourceUpdate?.embedUrl}
                          onChange={(e) =>
                            setResourceUpdate((prev) => {
                              return { ...prev, embedUrl: e.target.value };
                            })
                          }
                          name='embedUrl'
                          required
                          className='form-control'
                        />
                      </div>
                    </>
                  )} */}
                </div>

                <div className='form-group'>
                  <label htmlFor='resource-content'>Write Content</label>
                  <textarea
                    id='resource-content'
                    name='fileContent'
                    value={resourceUpdate?.fileContent}
                    onChange={(e) =>
                      setResourceUpdate((prev) => {
                        return { ...prev, fileContent: e.target.value };
                      })
                    }
                    placeholder='write here'
                    className='form-control form-control-sm my-2'
                  ></textarea>
                </div>
                <div>
                  <button
                    className='btn mt-2 btn-sm btn-success float-end text-uppercase'
                    type='submit'
                    disabled={loading}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      class='feather feather-save mb-1'
                    >
                      <path d='M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z'></path>
                      <polyline points='17 21 17 13 7 13 7 21'></polyline>
                      <polyline points='7 3 7 8 15 8'></polyline>
                    </svg>{' '}
                    {loading ? 'loading...' : 'Update'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Resource;
