import { Routes, Route } from 'react-router-dom';
import Login from '../components/login';
import ForgotPassword from '../components/ForgotPassword';
import Register from '../components/register';

function Guest() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
      </Routes>
    </div>
  );
}

export default Guest;
