import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from '../layouts/Navbar';
import SideBar from '../layouts/SideBar';
import NotFound from './NotFound';
import { createContext } from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import Group from '../pages/Group';
import Category from '../pages/Category';
import SubCategory from '../pages/SubCategory';
import Resource from '../pages/Resource';
import MedicalEducation from '../pages/MedicalEduction';
import { Toaster } from 'react-hot-toast';
import NewsAndViews from '../pages/NewsAndViews';
import Webinars from '../pages/Webinars';
import Users from '../pages/Users';
export const RoleContext = createContext();

const Auth = () => {
  return (
    <RoleContext.Provider value={'userRole'}>
      <Toaster position='top-center' />
      <div className='main-wrapper'>
        <SideBar />
        <div className='page-wrapper'>
          <Navbar></Navbar>
          <Routes>
            <Route path='/' element={<Dashboard />} exact />
            <Route path='group' element={<Group />} />
            <Route path='category' element={<Category />} />
            <Route path='sub-category' element={<SubCategory />} />
            <Route path='resource' element={<Resource />} />
            <Route path='medicaleducation' element={<MedicalEducation />} />
            <Route path='newsandviews' element={<NewsAndViews />} />
            <Route path='webinars' element={<Webinars />} />
            <Route path='users' element={<Users />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </RoleContext.Provider>
  );
};

export default Auth;
