import { useState } from 'react';
import AuthUser from './AuthUser';
import { Link, useNavigate } from 'react-router-dom';

export default function Register() {
  const navigate = useNavigate();
  const { http } = AuthUser();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const submitForm = () => {
    http
      .post('/auth/register', { name: name, email: email, password: password })
      .then((res) => {
        navigate('/');
      });
  };

  return (
    <div>
      <form
        style={{
          width: '300px',
          margin: '200px auto',
          display: 'flex',
          gap: '15px',
          flexDirection: 'column',
          background: 'whitesmoke',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div className='d-flex justify-content-center'>
          <img
            style={{ width: '35%' }}
            src={'./assets/images/img_logo1.png'}
            alt='slkgjslfjs'
            srcset=''
          />
        </div>
        <div className='form-group'>
          <label htmlFor='name'>Name</label>
          <input
            type='text'
            name='name'
            className='form-control'
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='email'>Email address</label>
          <input
            type='email'
            name='email'
            className='form-control'
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='password'>Password</label>
          <input
            type='password'
            password='password'
            className='form-control'
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <Link to={'/'}>Already registered</Link>
          <button
            type='button'
            onClick={submitForm}
            className='btn btn-primary'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
